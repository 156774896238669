<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      v-if="isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    v-model="feedbackSearch.searchText"
                    :label="$t('common.search')"
                    clearable
                    @keydown.enter="onSearch($event)"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch($event)"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <!--  -->
        <material-card
          class="card-tabs"
          color="green"
          full-width
        >
          <v-flex
            slot="header"
          >
            <v-tabs
              v-model="tabValue"
              color="transparent"
              slider-color="white"
            >
              <v-tab
                class="mr-3"
                @click="getData(0)">
                <!-- <v-icon class="mr-2">mdi-bug</v-icon> -->
                {{ $t('feedback.feedbackTemplate.title') }}
              </v-tab>
              <v-tab
                class="mr-3"
                @click="getData(1)">
                <!-- <v-icon class="mr-2">mdi-code-tags</v-icon> -->
                {{ $t('feedback.feedbackOptions.title') }}
              </v-tab>
            </v-tabs>
          </v-flex>
          <template>
            <!--  -->
            <feedbackTemplate
              v-show="tabValue === 0"
              ref="feedbackTemplate"/>
            <feedbackOptions
              v-show="tabValue === 1"
              ref="feedbackOptions"/>
          </template>
        </material-card>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <!-- <userDetailModal ref="userDetailModal" /> -->
    <loadingBar :is-loading="isLoading" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
import AddUserModal from 'Components/AddUserModal'
import Avatar from 'vue-avatar'
import RoleType from 'enum/roleType'
//
import feedbackTemplate from './FeedbackTemplate'
import feedbackOptions from './FeedbackOptions'
export default {
  components: {
    addUserModal: AddUserModal,
    Avatar,
    feedbackTemplate,
    feedbackOptions
  },
  data: () => ({
    tabValue: 0,
    menu: false,
    feedbackSearch: {
      searchText: null
    },
    isLoading: false
  }),
  computed: {
    ...mapGetters(['GET_SNACK_BAR', 'GET_PRODUCT_LIST_DATA', 'GET_CURRENT_ACCOUNT'])
  },
  watch: {},
  created () {
  },
  methods: {
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.hasSystemRole(RoleType.ADMIN, this.GET_CURRENT_ACCOUNT)
    },
    isModerator: function () {
      return functionUtils.hasSystemRole(RoleType.MODERATOR, this.GET_CURRENT_ACCOUNT)
    },
    // Check Accountant
    isAccountant: function () {
      return functionUtils.hasSystemRole(RoleType.ACCOUNTANT, this.GET_CURRENT_ACCOUNT)
    },
    onSearch: function (event) {
      event.preventDefault()
      if (this.tabValue === 0) {
        this.$refs.feedbackTemplate.onSearch(this.feedbackSearch.searchText)
      }
      if (this.tabValue === 1) {
        this.$refs.feedbackOptions.onSearch(this.feedbackSearch.searchText)
      }
    },
    getData: function (value) {
      switch (value) {
        case 0:
          this.$refs.feedbackTemplate.getFeedbackTemplateDataList(this.feedbackSearch.searchText)
          break
        case 1:
          this.$refs.feedbackOptions.onSearch(this.feedbackSearch.searchText)
          break
        default:
          break
      }
    },
    ...mapActions(['ON_SHOW_TOAST'])
  }
}
</script>
<style lang="scss" scoped>
@import "../../styles/material-dashboard/_colors.scss";
.v-tabs /deep/ .v-tabs__div a:hover{
  color: white;
}
// .v-tabs /deep/ .accent {
//   background: white  !important;
//   color: white !important;
// }
.v-tabs {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
</style>
