<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      v-if="isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
      wrap
    >
      <v-flex
        md12
      >
        <template class="float-right">
          <v-btn
            v-if="isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
            color="success"
            dark
            style="float: right;"
            @click="onShowAddFeedbackOption(null)"
          >
            {{ $t('common.add') }}
          </v-btn>
        </template>
        <v-data-table
          v-if="isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
          :no-data-text="$t('common.noDataAvailable')"
          :headers="userHeaders"
          :items="feedbackTemplateList"
          :single-select="true"
          hide-actions
        >
          <template
            slot="headerCell"
            slot-scope="{ header }"
          >
            <span
              class="text--darken-3 font-medium"
              v-text="$t(header.text)"
            />
          </template>
          <template
            v-slot:items="props"
          >
            <td style="white-space: nowrap; width: 5%;">{{ props.item.stt }}</td>
            <td
              class="cursor-pointer"
              @mouseover="onHoverEmail(props.item)"
              @mouseleave="onLeaveEmail(props.item)"
            >
              <div>
                <span
                  class="pb-1"
                  style="border-bottom: 1px dashed #5cb860">{{ props.item.answerOption }}</span>
                <div
                  v-show="props.item.isShowTool"
                  class="mt-1"
                  style="display: flex; white-space: nowrap">
                  <span
                    class="text-success hover-detail-text"
                    @click="onShowFeedbackOption(props.item)">{{ $t('users.viewDetail') }}</span>
                  <span
                    v-if="isAdmin() || isSuperAdmin() || isAccountant()"
                    class="mx-2">|</span>
                  <span
                    v-if="isAdmin() || isSuperAdmin() || isAccountant()"
                    class="hover-delete-text"
                    style="color: red;"
                    @click="onShowDeleteFeedbackOption(props.item.id)">{{ $t('common.delete') }}</span>
                </div>
              </div>
            </td>
            <td class="text-xs-center">
              <!-- <v-tooltip
                v-if="isAdmin() || isSuperAdmin() || isAccountant() || isModerator()"
                top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :loading="props.item.isLoading"
                    class="mx-2"
                    fab
                    small
                    color="success"
                    style="color: #ffffff;"
                    v-on="on"
                    @click="onShowModalFeedbackItem(props.item)">
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('common.resetPassword') }}</span>
              </v-tooltip> -->
            </td>
          </template>
        </v-data-table>
      <!-- </material-card> -->
      </v-flex>
    </v-layout>
    <pagination
      :total="feedbackTemplatePaginate.totalPage"
      :current-page="feedbackTemplatePaginate.currentPage"
      :row-per-page="feedbackTemplatePaginate.rowPerPage"
      @onPageChange="onPageChange"
    />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <confirm-modal
      ref="confirmModalDeleteFeedbackOption"
      :title="confirmModalTitle"
      @onConfirm="onDeleteFeedbackOption()"
    />
    <loadingBar :is-loading="isLoading" />
    <feedbackOptionsDetail
      ref="feedbackOptionsDetail"
      @getFeedbackOptionList="getFeedbackOptionList()"/>
  </v-container>
</template>
<script>
import functionUtils from 'utils/functionUtils'
import RoleType from 'enum/roleType'
import { mapActions, mapGetters } from 'vuex'
import feedbackOptionsDetail from './FeedbackOptionsDetail'
import ToastType from 'enum/toastType'

export default {
  components: {
    feedbackOptionsDetail
  },
  data: () => ({
    userHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'feedback.feedbackOptions.answerOption',
        value: 'answerOption'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    feedbackTemplateList: [],
    feedbackTemplatePaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    rolesSelected: [],
    isUpdate: true,
    dobDefault: null,
    menu: false,
    confirmModalTitle: '',
    selected: [],
    feedbackOptionSearch: {
      searchText: null
    },
    isLoading: false,
    feedbackOptionId: null
  }),
  computed: {
    ...mapGetters(['GET_CURRENT_ACCOUNT', 'GET_SNACK_BAR', 'GET_FEEDBACK_OPTION_DATA'])
  },
  watch: {
    GET_FEEDBACK_OPTION_DATA () {
      this.feedbackTemplatePaginate.currentPage = this.GET_FEEDBACK_OPTION_DATA.paginate.currentPage
      this.feedbackTemplatePaginate.totalPage = this.GET_FEEDBACK_OPTION_DATA.paginate.totalPage
      this.feedbackTemplatePaginate.rowPerPage = this.GET_FEEDBACK_OPTION_DATA.paginate.perPage
      let feedbackTemplateList = this.GET_FEEDBACK_OPTION_DATA.results
      this.feedbackTemplateList = []
      for (let i = 0, size = feedbackTemplateList.length; i < size; i++) {
        let userObj = {
          stt: i + 1 + (this.GET_FEEDBACK_OPTION_DATA.paginate.currentPage - 1) * this.GET_FEEDBACK_OPTION_DATA.paginate.pageSize,
          id: feedbackTemplateList[i].id,
          answerOption: feedbackTemplateList[i].answer_option,
          isShowTool: false,
          isLoading: false
        }
        this.feedbackTemplateList.push(userObj)
      }
      this.isLoading = false
    }
  },
  methods: {
    /**
     * Hover email
     */
    onHoverEmail: function (userItem) {
      userItem.isShowTool = true
    },
    /**
     * Leave email
     */
    onLeaveEmail: function (userItem) {
      userItem.isShowTool = false
    },
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.hasSystemRole(RoleType.ADMIN, this.GET_CURRENT_ACCOUNT)
    },
    isModerator: function () {
      return functionUtils.hasSystemRole(RoleType.MODERATOR, this.GET_CURRENT_ACCOUNT)
    },
    // Check Accountant
    isAccountant: function () {
      return functionUtils.hasSystemRole(RoleType.ACCOUNTANT, this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.feedbackTemplatePaginate.currentPage = page
      this.getFeedbackOptionList()
    },
    getFeedbackOptionList: function () {
      this.isLoading = true
      let filter = {
        params: {
          page: this.feedbackTemplatePaginate.currentPage,
          searchText: this.feedbackOptionSearch.searchText
        }
      }
      this.GET_FEEDBACK_OPTION(filter).then(
        function () {}
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    // add feedback option
    onShowAddFeedbackOption: function () {
      this.$refs.feedbackOptionsDetail.onShowAddNew()
    },
    // show detail feedback option
    onShowFeedbackOption: function (data) {
      this.$refs.feedbackOptionsDetail.onShowModal(data)
    },
    /**
     * Search
     */
    onSearch: function (text) {
      this.feedbackOptionSearch.searchText = text
      this.getFeedbackOptionList()
    },
    // delete feedback option
    onShowDeleteFeedbackOption: function (feedbackOptionId) {
      this.feedbackOptionId = feedbackOptionId
      this.confirmModalTitle = this.$t('feedback.feedbackOptions.confirmDeleteFeedbackOption')
      this.$refs.confirmModalDeleteFeedbackOption.onShowModal()
    },
    onDeleteFeedbackOption: function () {
      this.DELETE_FEEDBACK_OPTION({ id: this.feedbackOptionId }).then(
        function () {
          this.feedbackOptionId = null
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.getFeedbackOptionList()
        }.bind(this)
      )
    },
    ...mapActions(['GET_FEEDBACK_OPTION', 'DELETE_FEEDBACK_OPTION', 'ON_SHOW_TOAST'])
  }
}
</script>
<style lang="scss" scoped>
@import "../../styles/material-dashboard/_colors.scss";
.border-style {
  border-bottom: 2px solid $border-color;
}
.cursor-pointer {
  cursor: pointer !important;
}
.hover-detail-text:hover {
  border-bottom: 1px solid $border-color;
}
.hover-delete-text:hover {
  border-bottom: 1px solid $border-color;
}
.hover-active-text:hover {
  border-bottom: 1px solid $border-color;
}
</style>
