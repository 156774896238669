<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="$t('feedback.feedbackTemplate.addFeedbackOptionInTemplate')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout wrap>
              <v-flex
                xs12
                sm12
                md12>
                <v-autocomplete
                  :rules="[requiredValue]"
                  :label="$t('feedback.feedbackOptions.answerOption')"
                  v-model="feedbackOptionValue"
                  :items="feedbackOptionList"
                  :loading="isLoadingSelected"
                  item-text="answerOption"
                  item-value="id"
                  clearable
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    {{ data.item.answerOption }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data"
                    class="black-text"
                  >
                    {{ $t(data.item.answerOption) }}
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
  </v-dialog>
</template>
<script>
import functionUtils from 'utils/functionUtils'
import ToastType from 'enum/toastType'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {},
  data () {
    return {
      valid: false,
      isShowModal: false,
      menu: false,
      isLoading: false,
      isLoadingSelected: false,
      feedbackTemplateId: null,
      feedbackOptionList: [],
      feedbackOptionValue: null
    }
  },
  computed: {
    ...mapGetters(['GET_SNACK_BAR', 'GET_FEEDBACK_OPTION_NO_PAGE_DATA'])
  },
  watch: {
    GET_FEEDBACK_OPTION_NO_PAGE_DATA: function () {
      this.feedbackOptionList = []
      let data = this.GET_FEEDBACK_OPTION_NO_PAGE_DATA
      data.forEach(value => {
        let item = {
          id: value.id,
          answerOption: value.answer_option
        }
        this.feedbackOptionList.push(item)
      })
      this.isLoadingSelected = false
    }
  },
  created () {
    this.isLoadingSelected = true
  },
  methods: {
    /**
     * Required value
     */
    requiredValue (value) {
      if (value !== null && value !== undefined && value.length !== 0) {
        return true
      }
      return this.$t('common.requiredFields')
    },
    onShowModal: function (feedbackTemplateId) {
      this.feedbackTemplateId = feedbackTemplateId
      this.feedbackOptionValue = null
      this.isShowModal = true
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        this.isLoading = true
        let data = {
          feedback_template_id: this.feedbackTemplateId,
          feedback_option: this.feedbackOptionValue
        }
        this.ADD_FEEDBACK_OPTION_IN_TEMPLATE(data)
          .then(
            function () {
              this.isLoading = false
              this.isShowModal = false
              this.$emit('getFeedbackInTemplateList', true)
            }.bind(this)
          ).catch(
            function (error) {
              this.isLoading = false
              if (!functionUtils.isNull(error.response.data.errorText)) {
                let errorText = error.response.data.errorText
                this.ON_SHOW_TOAST({
                  'message': this.$t(`${errorText}`),
                  styletype: ToastType.ERROR
                })
              } else {
                this.ON_SHOW_TOAST({
                  'message': this.$t('login.somethingWentWrong'),
                  styletype: ToastType.ERROR
                })
              }
            }.bind(this)
          )
      }
    },
    ...mapActions(['ON_SHOW_TOAST', 'ADD_FEEDBACK_OPTION_IN_TEMPLATE'])
  }

}
</script>
<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
