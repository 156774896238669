<template>
  <v-dialog
    v-model="isShowModal"
    width="1200px"
  >
    <v-card>
      <v-container
        grid-list-xl
        fluid
        style="padding-top: 0;">
        <v-form>
          <v-layout
            v-if="isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
            wrap
          >
            <v-flex
              md12
            >
              <material-card
                :title="$t('common.search')"
                color="green"
                flat
                full-width
                class="bg-white"
              >
                <v-form>
                  <v-container py-0>
                    <v-layout wrap>
                      <v-flex
                        xs12
                        md3>
                        <v-text-field
                          v-model="feedbackSearch.searchText"
                          :label="$t('common.search')"
                          clearable
                          @keydown.enter="onSearch($event)"
                        />
                      </v-flex>
                    </v-layout>
                    <v-btn
                      style="margin-right: 0;"
                      class="pull-right"
                      color="success"
                      @click="onSearch($event)"
                    >
                      <span class="btn-label mr-3">
                        <i class="glyphicon glyphicon-search" />
                      </span>
                      <span style="text-transform: none;">{{
                        $t("common.search")
                      }}</span>
                    </v-btn>
                  </v-container>
                </v-form>
              </material-card>
              <template class="float-right">
                <v-btn
                  v-if="isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
                  color="success"
                  dark
                  style="float: right;"
                  @click="onShowAddFeedbackOption()"
                >
                  {{ $t('common.add') }}
                </v-btn>
              </template>
              <v-data-table
                v-if="isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
                :no-data-text="$t('common.noDataAvailable')"
                :headers="userHeaders"
                :items="feedbackInTemplateList"
                :single-select="true"
                hide-actions
              >
                <template
                  slot="headerCell"
                  slot-scope="{ header }"
                >
                  <span
                    class="text--darken-3 font-medium"
                    v-text="$t(header.text)"
                  />
                </template>
                <template
                  v-slot:items="props"
                >
                  <td style="white-space: nowrap; width: 5%;">{{ props.item.stt }}</td>
                  <td
                    class="cursor-pointer"
                    @mouseover="onHoverEmail(props.item)"
                    @mouseleave="onLeaveEmail(props.item)"
                  >
                    <div>
                      <span
                        class="pb-1"
                        style="border-bottom: 1px dashed #5cb860">{{ props.item.answerOption }}</span>
                      <div
                        v-show="props.item.isShowTool"
                        class="mt-1"
                        style="display: flex; white-space: nowrap">
                        <!-- <span
                          class="text-success hover-detail-text"
                          @click="onShowFeedbackOption(props.item)">{{ $t('users.viewDetail') }}</span>
                        <span
                          v-if="isAdmin() || isSuperAdmin() || isAccountant()"
                          class="mx-2">|</span> -->
                        <span
                          v-if="isAdmin() || isSuperAdmin() || isAccountant()"
                          class="hover-delete-text"
                          style="color: red;"
                          @click="onShowDeleteFeedbackOptionInTemplate(props.item.id)">{{ $t('common.delete') }}</span>
                      </div>
                    </div>
                  </td>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-form>
        <pagination
          v-if="isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
          :total="userPaginate.totalPage"
          :current-page="userPaginate.currentPage"
          :row-per-page="userPaginate.rowPerPage"
          @onPageChange="onPageChange"
        />
        <snack-bar
          v-for="snackbar in GET_SNACK_BAR"
          :key="snackbar.id"
          :item="snackbar"/>
        <loadingBar :is-loading="isLoading" />
      </v-container>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <addFeedbackInTemplate
      ref="addFeedbackInTemplate"
      @getFeedbackInTemplateList="getFeedbackInTemplate()"
    />
    <!--  -->
    <confirm-modal
      ref="confirmModalDeleteFeedbackOptionInTemplate"
      :title="confirmModalTitle"
      @onConfirm="onDeleteFeedbackOptionInTemplate()"
    />
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
import RoleType from 'enum/roleType'
import TransactionType from 'enum/transactionType'
import addFeedbackInTemplate from './AddFeedbackInTemplate'
import ToastType from 'enum/toastType'
export default {
  components: {
    addFeedbackInTemplate
  },
  data: () => ({
    isShowModal: false,
    userHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'feedback.feedbackOptions.answerOption',
        value: 'answerOption'
      }
    ],
    feedbackInTemplateList: [],
    userPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    menu: false,
    feedbackSearch: {
      searchText: null,
      customerType: null
    },
    isLoading: false,
    transactionType: TransactionType,
    feedbackTemplateId: null,
    confirmModalTitle: '',
    feedbackOptionId: null
  }),
  computed: {
    ...mapGetters(['GET_SNACK_BAR', 'GET_FEEDBACK_OPTION_IN_TEMPLATE_DATA', 'GET_CURRENT_ACCOUNT'])
  },
  watch: {
    GET_FEEDBACK_OPTION_IN_TEMPLATE_DATA () {
      this.userPaginate.currentPage = this.GET_FEEDBACK_OPTION_IN_TEMPLATE_DATA.paginate.currentPage
      this.userPaginate.totalPage = this.GET_FEEDBACK_OPTION_IN_TEMPLATE_DATA.paginate.totalPage
      this.userPaginate.rowPerPage = this.GET_FEEDBACK_OPTION_IN_TEMPLATE_DATA.paginate.perPage
      let feedbackInTemplateList = this.GET_FEEDBACK_OPTION_IN_TEMPLATE_DATA.results
      this.feedbackInTemplateList = []
      for (let i = 0, size = feedbackInTemplateList.length; i < size; i++) {
        let userObj = {
          stt: i + 1 + (this.GET_FEEDBACK_OPTION_IN_TEMPLATE_DATA.paginate.currentPage - 1) * this.GET_FEEDBACK_OPTION_IN_TEMPLATE_DATA.paginate.pageSize,
          id: feedbackInTemplateList[i].id,
          answerOption: feedbackInTemplateList[i].answer_option,
          isShowTool: false,
          isLoading: false
        }
        this.feedbackInTemplateList.push(userObj)
      }
      this.isLoading = false
    }
  },
  created () {},
  methods: {
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    // on show modal
    onShowModal: function (feedbackTemplateId) {
      this.feedbackTemplateId = feedbackTemplateId
      this.feedbackSearch.searchText = null
      this.getFeedbackInTemplate()
      this.isShowModal = true
    },
    /**
     * Hover email
     */
    onHoverEmail: function (userItem) {
      userItem.isShowTool = true
    },
    /**
     * Leave email
     */
    onLeaveEmail: function (userItem) {
      userItem.isShowTool = false
    },
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.hasSystemRole(RoleType.ADMIN, this.GET_CURRENT_ACCOUNT)
    },
    isModerator: function () {
      return functionUtils.hasSystemRole(RoleType.MODERATOR, this.GET_CURRENT_ACCOUNT)
    },
    // Check Accountant
    isAccountant: function () {
      return functionUtils.hasSystemRole(RoleType.ACCOUNTANT, this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Search
     */
    onSearch: function (event) {
      event.preventDefault()
      this.getFeedbackInTemplate()
    },
    /**
     * Get feedback in template list
     */
    getFeedbackInTemplate: function () {
      this.isLoading = true
      let filter = {
        params: {
          page: this.userPaginate.currentPage,
          searchText: this.feedbackSearch.searchText,
          feedback_template_id: this.feedbackTemplateId
        }
      }
      this.GET_FEEDBACK_OPTION_IN_TEMPLATE(filter).then(
        function () {}
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.userPaginate.currentPage = page
      this.getFeedbackInTemplate()
    },
    // show add feeddback option
    onShowAddFeedbackOption: function () {
      this.$refs.addFeedbackInTemplate.onShowModal(this.feedbackTemplateId)
    },
    // on show delete feedback option
    onShowDeleteFeedbackOptionInTemplate: function (feedbackOptionId) {
      this.feedbackOptionId = feedbackOptionId
      this.confirmModalTitle = this.$t('feedback.confirmDeleteFeedbackOptionInTemplate')
      this.$refs.confirmModalDeleteFeedbackOptionInTemplate.onShowModal()
    },
    onDeleteFeedbackOptionInTemplate: function () {
      let data = {
        feedback_template_id: this.feedbackTemplateId,
        feedback_option: this.feedbackOptionId
      }
      this.DELETE_FEEDBACK_OPTION_IN_TEMPLATE(data).then(
        function () {
          this.feedbackOptionId = null
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.getFeedbackInTemplate()
        }.bind(this)
      )
    },
    ...mapActions(['GET_FEEDBACK_OPTION_IN_TEMPLATE', 'DELETE_FEEDBACK_OPTION_IN_TEMPLATE', 'ON_SHOW_TOAST'])
  }
}
</script>
<style lang="scss" scoped>
@import "../../styles/material-dashboard/_colors.scss";
/deep/ .test-primary {
  background-color: #c7c7c7c7 !important;
}
/deep/ .test-primary:hover {
  background-color: #c7c7c7c7 !important;
}
/deep/ .theme--light.v-list {
  background-color: transparent;
}
.border-style {
  border-bottom: 2px solid $border-color;
}
.cursor-pointer {
  cursor: pointer !important;
}
.hover-detail-text:hover {
  border-bottom: 1px solid $border-color;
}
.hover-delete-text:hover {
  border-bottom: 1px solid $border-color;
}
.hover-active-text:hover {
  border-bottom: 1px solid $border-color;
}
// .modal {
//   box-shadow: 2px 2px 20px 1px;
//   overflow-x: auto;
//   display: flex;
//   flex-direction: column;
//   overflow-y: auto;
// }
// .v-card {
//   margin-bottom: 0px !important;
//   margin-top: 21px !important;
// }
// /deep/.required label::after {
//   content: " *";
//   color: red;
// }
</style>
