<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="feedbackData.id === 0 ? $t('feedback.feedbackTemplate.addFeedbackTemplate') : $t('feedback.feedbackTemplate.detailFeedbackTemplate')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout wrap>
              <v-flex
                xs12
                sm12
                md12>
                <v-text-field
                  :rules="[requiredValue]"
                  v-model="feedbackData.templateName"
                  :label="$t('feedback.feedbackTemplate.templateName')"
                  prepend-icon="mdi-text"
                  class="purple-input required"
                  type="text"
                />
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex
                xs12
                sm12
                md12>
                <v-select
                  :rules="[requiredValue]"
                  v-model="feedbackData.ratingNumber"
                  :items="feedbackTemplateRatingList"
                  :label="$t('feedback.feedbackTemplate.ratingNumber')"
                  prepend-icon="mdi-star-check-outline"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import functionUtils from 'utils/functionUtils'
import ToastType from 'enum/toastType'
import { mapActions } from 'vuex'
import stringUtils from 'utils/stringUtils'
export default {
  components: {},
  data () {
    return {
      valid: false,
      isShowModal: false,
      menu: false,
      isLoading: false,
      feedbackData: {
        id: 0,
        templateName: null,
        ratingNumber: null
      },
      feedbackTemplateRatingList: stringUtils.feedbackTemplateRatingList
    }
  },
  created () {},
  methods: {
    /**
     * Required value
     */
    requiredValue (value) {
      if (value !== null && value !== undefined && value.length !== 0) {
        return true
      }
      return this.$t('common.requiredFields')
    },
    isGreaterThanZero (value) {
      value = functionUtils.formatInteger(value)
      if (value > 0) {
        return true
      }
      return this.$t('users.valueMustBeGreaterThanZero')
    },
    onShowAddNew: function () {
      this.isShowModal = true
      this.feedbackData = {
        id: 0,
        templateName: null,
        ratingNumber: null
      }
    },
    onShowModal: function (userData) {
      this.feedbackData = {
        id: userData.id,
        templateName: userData.templateName,
        ratingNumber: userData.ratingNumber
      }
      this.isShowModal = true
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        this.isLoading = true
        let data = {
          id: this.feedbackData.id,
          name: this.feedbackData.templateName,
          rating_range: this.feedbackData.ratingNumber
        }
        if (this.feedbackData.id === 0) {
          this.CREATE_FEEDBACK_TEMPLATE(data)
            .then(
              function () {
                this.isLoading = false
                this.isShowModal = false
                this.$emit('getFeedbackTemplateList', true)
              }.bind(this)
            ).catch(
              function (error) {
                this.isLoading = false
                if (!functionUtils.isNull(error.response.data.errorText)) {
                  let errorText = error.response.data.errorText
                  this.ON_SHOW_TOAST({
                    'message': this.$t(`${errorText}`),
                    styletype: ToastType.ERROR
                  })
                } else if (!functionUtils.isNull(error.response.data.message) && error.response.data.message.includes('(identification)')) {
                  this.ON_SHOW_TOAST({
                    'message': this.$t('users.errorText.indentAlreadyExist'),
                    styletype: ToastType.ERROR
                  })
                } else {
                  this.ON_SHOW_TOAST({
                    'message': this.$t('login.somethingWentWrong'),
                    styletype: ToastType.ERROR
                  })
                }
              }.bind(this)
            )
        } else {
          this.UPDATE_FEEDBACK_TEMPLATE(data)
            .then(
              function () {
                this.isLoading = false
                this.isShowModal = false
                this.$emit('getFeedbackTemplateList', true)
              }.bind(this)
            ).catch(
              function (error) {
                this.isLoading = false
                if (!functionUtils.isNull(error.response.data.errorText)) {
                  let errorText = error.response.data.errorText
                  this.ON_SHOW_TOAST({
                    'message': this.$t(`${errorText}`),
                    styletype: ToastType.ERROR
                  })
                } else if (!functionUtils.isNull(error.response.data.message) && error.response.data.message.includes('(identification)')) {
                  this.ON_SHOW_TOAST({
                    'message': this.$t('users.errorText.indentAlreadyExist'),
                    styletype: ToastType.ERROR
                  })
                } else {
                  this.ON_SHOW_TOAST({
                    'message': this.$t('login.somethingWentWrong'),
                    styletype: ToastType.ERROR
                  })
                }
              }.bind(this)
            )
        }
      }
    },
    ...mapActions(['ON_SHOW_TOAST', 'CREATE_FEEDBACK_TEMPLATE', 'UPDATE_FEEDBACK_TEMPLATE'])
  }

}
</script>
<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
